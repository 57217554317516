(function () {
  "use strict";
  angular.module("marketPlace.home").controller("MenuController", menuController);

  menuController.$inject = [
    "$log",
    "$state",
    "$scope",
    "user",
    "CMS_URL",
    "CMSMenu",
    "AuthService",
    "Pages",
    "Features",
    "Niches",
    "searches",
    "_",
  ];

  function menuController(
    $log,
    $state,
    $scope,
    user,
    CMS_URL,
    CMSMenu,
    AuthService,
    Pages,
    Features,
    Niches,
    searches,
    _
  ) {
    const vm = this;
    vm.user = user;
    vm.showInfo = false;
    vm.asyncMenusLoading = true;
    vm.cmsMenuLoading = true;
    vm.pageMenuLoading = true;

    vm.logout = logout;
    vm.toggleInfo = toggleInfo;
    vm.isActive = isActive;
    vm.enableShoppingCart = Features.shoppingCart;

    activate();

    function activate() {
      $log.debug("MenuController");

      Niches.read.query(function (niches) {
        vm.niches = niches;
      });

      if (CMS_URL) {
        CMSMenu.query()
          .$promise.then(
            function (resp) {
              vm.cmsMenu = resp;
            },
            function () {
              vm.cmsMenu = { items: [] };
            }
          )
          .finally(function () {
            vm.asyncMenusLoading = false;
            vm.cmsMenuLoading = false;
          });
      } else {
        vm.cmsMenu = { items: [] };
        vm.asyncMenusLoading = false;
        vm.cmsMenuLoading = false;
      }

      Pages.query()
        .then(
          function (resp) {
            const contentPages = [];
            for (let i = 0; i < resp.data.length; i++) {
              if (resp.data[i].include_in_menu) {
                contentPages.push(resp.data[i]);
              }
            }
            vm.contentPages = contentPages;
          },
          function () {
            vm.contentPages = [];
          }
        )
        .finally(function () {
          vm.asyncMenusLoading = false;
          vm.pageMenuLoading = false;
        });
      vm.enableCreateListing = Features.createListing;
      updateSelected($state.params);

      if (
        angular.isDefined(searches) &&
        searches !== null &&
        searches.hasOwnProperty("results") &&
        angular.isDefined(searches.results) &&
        searches.results !== null
      ) {
        const watchlists = _.filter(searches.results, function (o) {
          return o.name === "watchlist";
        });
        vm.watchlist = _.pick(watchlists[0], ["items"]);
      }
    }

    function toggleInfo() {
      if (vm.asyncMenusLoading) {
        return;
      }
      vm.showInfo = !vm.showInfo;
    }

    function setLocation(selectedNiche, selectedSection, selectedGallery, selectedCaption) {
      vm.selectedNiche = selectedNiche;
      vm.selectedSection = selectedSection;
      vm.selectedGallery = selectedGallery;
      vm.selectedCaption = selectedCaption;

      if (angular.isDefined(vm.niches) && angular.isDefined(selectedNiche) && vm.niches.hasOwnProperty(selectedNiche)) {
        const niche = vm.niches[selectedNiche];
        niche.open = true;
        if (
          angular.isDefined(niche.sections) &&
          angular.isDefined(selectedSection) &&
          niche.sections.hasOwnProperty(selectedSection)
        ) {
          const section = niche.sections[selectedSection];
          section.open = true;
          if (
            angular.isDefined(section.galleries) &&
            angular.isDefined(selectedGallery) &&
            section.galleries.hasOwnProperty(selectedGallery)
          ) {
            const gallery = section.galleries[selectedGallery];
            gallery.open = true;
            if (
              angular.isDefined(gallery.captions) &&
              angular.isDefined(selectedCaption) &&
              gallery.captions.hasOwnProperty(selectedCaption)
            ) {
              gallery.captions[selectedCaption].open = true;
            }
          }
        }
      }
    }

    function updateSelected(params) {
      vm.selectedNiche = params.niche;
      vm.selectedSection = params.section;
      vm.selectedGallery = params.gallery;
      vm.selectedCaption = params.caption;
      setLocation(vm.selectedNiche, vm.selectedSection, vm.selectedGallery, vm.selectedCaption);
    }

    /**
     * Watch for state param changes to we can updated selected values
     */
    $scope.$watchCollection(
      function () {
        return $state.params;
      },
      function () {
        updateSelected($state.params);
      }
    );

    function logout() {
      AuthService.logout();
    }

    function isActive(route) {
      return $state.current.name.startsWith(route);
    }
  }
})();
