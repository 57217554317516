/* eslint-env node */

angular
  .module("marketPlace.constants", [])

  .constant(
    "MAX_UNAUTHENTICATED_OPENS",
    process.env.APP_MAX_UNAUTHENTICATED_OPENS === "null" ? null : parseInt(process.env.APP_MAX_UNAUTHENTICATED_OPENS)
  )
  .constant(
    "MAX_UNAUTHENTICATED_SCROLLS",
    process.env.APP_MAX_UNAUTHENTICATED_SCROLLS === "null" ? null : parseInt(process.env.MAX_UNAUTHENTICATED_SCROLLS)
  )

  .constant("API_URL", process.env.API_URL)

  .constant("CMS_URL", process.env.CMS_URL)

  .constant("ENV", process.env.ENV)

  .constant("SITE_NAME", process.env.SITE_NAME)

  .constant("AppConfig", {
    itemExternalImageLimit: process.env.APP_ITEM_EXTERNAL_IMAGE_LIMIT,
    eventExternalImageLimit: process.env.APP_EVENT_EXTERNAL_IMAGE_LIMIT,
    organisationImageLimit: process.env.APP_ORGANISATION_EXTERNAL_IMAGE_LIMIT,
    S3Config: {
      s3url: process.env.S3_URL,
      acl: process.env.S3_ACL,
      policy: process.env.S3_POLICY,
      signature: process.env.S3_SIGNATURE,
      AWSAccessKeyId: process.env.S3_AWS_ACCESS_KEY_ID,
    },
    GoogleAPIKey: process.env.GOOGLE_API_KEY,
    FacebookAppID: process.env.FACEBOOK_APP_ID,
    GoogleClientId: process.env.GOOGLE_CLIENT_ID,
    TransactionFee: process.env.APP_TRANSACTION_FEE,
    TransactionRate: process.env.APP_TRANSACTION_RATE,
    LogoutURL: process.env.APP_LOGOUT_URL,
    CMSHomePageID: process.env.APP_CMS_HOME_PAGE_ID,
    customLogo: process.env.APP_CUSTOM_LOGO,
    customSmallBanner: process.env.APP_CUSTOM_SMALL_BANNER,
    sitenameInNavbar:
      process.env.APP_SITENAME_IN_NAVBAR !== undefined
        ? JSON.parse(process.env.APP_SITENAME_IN_NAVBAR.toLowerCase())
        : false,
    adFrequency: process.env.GALLERY_AD_FREQUENCY,
    advancedSearchToggle:
      process.env.APP_ADVANCED_SEARCH_DEFAULT_OPEN !== undefined
        ? JSON.parse(process.env.APP_ADVANCED_SEARCH_DEFAULT_OPEN.toLowerCase())
        : false,
    defaultCurrency: process.env.APP_DEFAULT_CURRENCY,
    categoryLabel: process.env.APP_CATEGORY_LABEL !== undefined ? process.env.APP_CATEGORY_LABEL : "Category",
    showMoreUserOnly:
      process.env.APP_SHOW_MORE_USER_ONLY !== undefined
        ? JSON.parse(process.env.APP_SHOW_MORE_USER_ONLY.toLowerCase())
        : false,
    collectsLabel:
      process.env.APP_COLLECTS_LABEL !== undefined ? process.env.APP_COLLECTS_LABEL : "What do you collect?",
    collectsRequired:
      process.env.APP_COLLECTS_REQUIRED !== undefined ? JSON.parse(process.env.APP_COLLECTS_REQUIRED.toLowerCase()) : false,
  })

  .constant("broadStreetZones", {
    inactivityPopup: process.env.BROADSTREET_INACTIVITY_POPUP,
    mainBanner: process.env.BROADSTREET_MAIN_BANNER,
    sidebar: process.env.BROADSTREET_SIDEBAR,
    sidebar2: process.env.BROADSTREET_SIDEBAR_2,
    sidebar3: process.env.BROADSTREET_SIDEBAR_3,
    gallery: process.env.BROADSTREET_GALLERY,
    galleryMobile1: process.env.BROADSTREET_GALLERY_MOBILE_1,
    galleryMobile2: process.env.BROADSTREET_GALLERY_MOBILE_2,
    fullPage: process.env.BROADSTREET_FULL_PAGE,
    mobileBanner: process.env.BROADSTREET_MOBILE_BANNER,
    detailBanner: process.env.BROADSTREET_DETAIL_BANNER,
    detailBanner2: process.env.BROADSTREET_DETAIL_BANNER_2,
    headerBanner: process.env.BROADSTREET_HEADER_BANNER,
  })

  .constant("BroadstreetNetworkID", process.env.BROADSTREET_NETWORK_ID)

  .constant("GoogleAnalyticsId", process.env.GOOGLE_ANALYTICS_ID)

  .constant("socialMediaLinks", {
    facebook: process.env.SOCIAL_MEDIA_FACEBOOK,
    pinterest: process.env.SOCIAL_MEDIA_PINTEREST,
    linkedin: process.env.SOCIAL_MEDIA_LINKEDIN,
    twitter: process.env.SOCIAL_MEDIA_TWITTER,
    hashtags: process.env.SOCIAL_MEDIA_HASHTAGS,
    emailSubject: process.env.SOCIAL_MEDIA_EMAIL_SUBJECT,
  })

  .constant("Features", {
    shoppingCart:
      process.env.FEATURE_SHOPPING_CART !== undefined
        ? JSON.parse(process.env.FEATURE_SHOPPING_CART.toLowerCase())
        : true,
    createListing:
      process.env.FEATURE_CREATE_LISTING !== undefined
        ? JSON.parse(process.env.FEATURE_CREATE_LISTING.toLowerCase())
        : true,
    businessRegistration:
      process.env.FEATURE_BUSINESS_REGISTRATION !== undefined
        ? JSON.parse(process.env.FEATURE_BUSINESS_REGISTRATION.toLowerCase())
        : true,
    games: process.env.FEATURE_GAMES !== undefined ? JSON.parse(process.env.FEATURE_GAMES.toLowerCase()) : true,
    auctions:
      process.env.FEATURE_AUCTIONS !== undefined ? JSON.parse(process.env.FEATURE_AUCTIONS.toLowerCase()) : true,
    broadstreet: process.env.BROADSTREET_NETWORK_ID ? true : false,
    typeFilters: process.env.TYPE_FILTERS !== undefined ? JSON.parse(process.env.TYPE_FILTERS) : false,
    scrollMessage: process.env.SCROLL_MESSAGE ? true : false,
  });
