import { ILogService, IScope, ITimeoutService } from "angular";
import { StateService } from "@uirouter/angularjs";
import { RavenStatic } from "raven-js";
import { IModalService } from "angular-ui-bootstrap";
import { LoDashStatic } from "lodash";
import { IToastrService } from "angular-toastr";

(function () {
  "use strict";

  angular.module("marketPlace.auth").component("registerModal", {
    template: require("./templates/register.html"),
    bindings: {
      resolve: "<",
      close: "&",
      dismiss: "&",
    },
    controller: registerModalCtrl,
  });

  registerModalCtrl.$inject = [
    "$scope",
    "$log",
    "$state",
    "Registration",
    "$timeout",
    "Invite",
    "_",
    "moment",
    "$auth",
    "toastr",
    "CMS_URL",
    "SITE_NAME",
    "Raven",
    "$uibModal",
    "Features",
    "AppConfig",
    "$window"
  ];

  function registerModalCtrl(
    $scope: IScope,
    $log: ILogService,
    $state: StateService,
    Registration,
    $timeout: ITimeoutService,
    Invite,
    _: LoDashStatic,
    moment,
    $auth,
    toastr: IToastrService,
    CMS_URL: string,
    SITE_NAME: string,
    Raven: RavenStatic,
    $uibModal: IModalService,
    Features,
    AppConfig,
    $window    
  ) {
    const $ctrl = this;

    enum accountType {
      business = "B",
      individual = "I",
    }

    $ctrl.register = register;
    $ctrl.cancel = cancel;
    $ctrl.authenticate = authenticate;
    $ctrl.chooseOrganisationType = chooseOrganisationType;
    $ctrl.chooseUserType = chooseUserType;

    $ctrl.$onInit = function () {
      const stateParams = $ctrl.resolve.$transition$.params();
      const currentYear = moment().year();
      const numYears = 110;
      const oldestYear = currentYear - numYears;
      const maxYear = currentYear - 14;
      $ctrl.years = _.rangeRight(oldestYear, maxYear);

      $ctrl.groups = $ctrl.resolve.groups;
      $log.debug("$onInit GROUPS", $ctrl.groups);

      $ctrl.settings = $ctrl.resolve.settings;

      $ctrl.CMS_URL = CMS_URL;
      $ctrl.siteName = SITE_NAME;
      $ctrl.enableBusinessRegistration = Features.businessRegistration;
      $ctrl.collectsLabel = AppConfig.collectsLabel;
      $ctrl.collectsRequired = AppConfig.collectsRequired;

      $ctrl.errors = {};
      $ctrl.registerForm = {};

      $ctrl.isInvite = false;
      $ctrl.isOrgInvite = false;
      $ctrl.showOrganisationTypes = false;
      $ctrl.showForm = false;
      $ctrl.showUserTypes = false;
      $ctrl.userType = null;
      $ctrl.checkingToken = false;
      $ctrl.require_birthday = false;
      $ctrl.require_collects = true;
      $ctrl.require_username = true;
      $ctrl.modalTitle = "Register for Free";
      $ctrl.businessRegistration = false;
      $ctrl.saving = false;
      $ctrl.socialLoginInProgress = false;

      if ($ctrl.resolve.$transition$.to().name.indexOf("business") >= 0) {
        initBusinessRegistration();
      }

      if (angular.isDefined(stateParams.token)) {
        $log.debug("Token found");
        let token = stateParams.token;
        $ctrl.checkingToken = true;
        $ctrl.require_collects = false;
        $ctrl.isInvite = true;

        if (angular.isDefined(stateParams.id)) {
          token = stateParams.id + "-" + token;
        }

        if ($ctrl.businessRegistration) {
          $ctrl.isOrgInvite = true;
        }

        Invite.get(
          { token: token },
          function (user) {
            $log.debug("Token accepted");
            $ctrl.user = user;
            $ctrl.user.token = stateParams.token;
            $log.debug("GROUPS", $ctrl.user.groups.length);
            if (!$ctrl.user.hasOwnProperty("groups") || $ctrl.user.groups.length === 0) {
              $ctrl.user.groups = [getFirstIndividualGroup()];
            }

            $ctrl.showForm = true;
            $ctrl.checkingToken = false;
            $ctrl.user.receive_emails = true;
            $ctrl.user.accept_terms = true;
          },
          function () {
            $log.debug("Token rejected");
            toastr.error("Invalid Token - Your invitation may have expired");
            $ctrl.checkingToken = false;
            $ctrl.showUserTypes = true;
          }
        );
      } else {
        $log.debug("Token NOT found");

        if ($ctrl.businessRegistration) {
          $ctrl.showOrganisationTypes = true;
        } else if ($ctrl.enableBusinessRegistration) {
          $ctrl.showUserTypes = true;
        } else {
          createNewUser();
          $ctrl.showForm = true;
        }
      }
    };

    function initBusinessRegistration() {
      $ctrl.modalTitle = "Register your business";
      $ctrl.require_collects = false;
      $ctrl.require_username = false;
      $ctrl.businessRegistration = true;
    }

    function chooseOrganisationType(option: number, name: string) {
      $log.debug("chooseOrganisationType ", option, name);
      createNewUser(option);
      $ctrl.userType = name;
      $ctrl.showForm = true;
      $ctrl.showOrganisationTypes = false;
      $timeout(function () {
        $scope.$broadcast("show-errors-reset");
      });
    }

    function chooseUserType(userType: accountType) {
      $log.debug("chooseUserType ", userType);
      $ctrl.showUserTypes = false;
      if (userType === accountType.business) {
        initBusinessRegistration();

        // if theres only one group then select it and don't show the choices
        const businessGroups = _.filter($ctrl.groups, { type: "B" });
        if (businessGroups.length === 1) {
          chooseOrganisationType(businessGroups[0]["id"], businessGroups[0]["name"]);
        } else {
          $ctrl.showOrganisationTypes = true;
        }
      } else {
        createNewUser();
        $ctrl.showForm = true;
      }
    }

    function getFirstIndividualGroup() {
      $log.debug("GROUPS", $ctrl.groups);
      for (let i = 0; i < $ctrl.groups.length; i++) {
        if ($ctrl.groups[i].type === accountType.individual) {
          return $ctrl.groups[i].id;
        }
      }
    }

    function createNewUser(group?: number) {
      if (angular.isUndefined(group) || group === null) {
        group = getFirstIndividualGroup();
      }

      $ctrl.user = new Registration({
        groups: [group],
        receive_emails: true,
        accept_terms: true,
      });
    }

    function register() {
      $scope.$broadcast("show-errors-check-validity");

      $log.debug("$error", $ctrl.registerForm.$error);
      $log.debug("$pristine", $ctrl.registerForm.$pristine);
      $log.debug("$valid", $ctrl.registerForm.$valid);
      $log.debug("$invalid", $ctrl.registerForm.$invalid);
      $log.debug("$submitted", $ctrl.registerForm.$submitted);
      $log.debug("$dirty", $ctrl.registerForm.$dirty);
      if ($ctrl.registerForm.$invalid) {
        return;
      }

      $ctrl.saving = true;

      $log.debug($ctrl.user);
      if ($ctrl.user.hasOwnProperty("token") && !$ctrl.user.token.startsWith($ctrl.user.id + "-")) {
        $ctrl.user.token = $ctrl.user.id + "-" + $ctrl.user.token;
      }

      $ctrl.user.$save(registrationSuccess, function (resp) {
        $log.debug("Could not register");
        if (resp.data && resp.status !== 500) {
          let errorCodes: string[] = [];
          if (resp.data.hasOwnProperty("error_codes")) {
            errorCodes = resp.data["error_codes"];
            delete resp.data["error_codes"];
          }

          angular.forEach(resp.data, function (errors, field) {
            $log.debug(field + " : " + errors);
            try {
              $ctrl.registerForm[field].$setValidity("server", false);
              if (errorCodes.indexOf("unclaimed_user") > 0) {
                $ctrl.errors[field] =
                  "This email is registered but unclaimed, check your email for a code or email support@madonc.com";
              } else {
                $ctrl.errors[field] = errors.join(", ");
              }
            } catch (e) {
              $log.error(resp.status + ": " + resp.statusText);
              if (angular.isArray(errors)) {
                errors = errors.join(", ");
              }
              $ctrl.errors.non_field_errors = errors;
            }
          });
        } else {
          $log.error(resp.status + ": " + resp.statusText);
          toastr.error("Sorry there was a problem with your registration");
        }
        $ctrl.saving = false;
        $scope.$broadcast("show-errors-check-validity");
      });
    }

    function cancel() {
      $scope.$broadcast("show-errors-reset");
      $ctrl.dismiss({ $value: "cancel" });
    }

    function authenticate(provider: string) {
      $ctrl.socialLoginInProgress = true;
      $auth
        .authenticate(provider, $ctrl.user)
        .then(registrationSuccess)
        .catch(function (error) {
          $ctrl.socialLoginInProgress = false;
          if (error.data && error.status) {
            toastr.error(error.data.message, error.status);
          } else if (!error.message) {
            toastr.error("Sorry there was a problem with your registration");
          }
        });
    }

    function registrationSuccess(resp) {
      $log.debug("Successful registration");
      $log.debug(resp);

      // login automatically after registering by setting token
      $auth.setToken({ data: resp });

      $window.gtag("event", "sign_up");

      // Set context for Sentry
      const payload = $auth.getPayload();
      Raven.setUserContext({
        email: payload.email,
      });

      if ($ctrl.businessRegistration || (resp.user && resp.user.hasOwnProperty("organisation"))) {
        const modalInstance = $uibModal.open({
          animation: true,
          component: "upgradeSubModal",
          size: "xl",
          windowClass: "upgradeModal",
          backdrop: "static",
          keyboard: false,
          resolve: {
            user: function () {
              return resp.user;
            },
            currentSubscription: function () {
              return {
                status: null,
                price: "0",
                billing_frequency: 1,
              };
            },
            showGroupChoices: function () {
              return false;
            },
          },
        });

        modalInstance.result.finally(function () {
          $log.debug("Subscription modal closed");
          $state.go("home.dashboard.edit-organisation", { new: true });
        });
      }
      $ctrl.saving = false;
      $ctrl.close();
    }
  }
})();
